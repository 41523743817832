import React from 'react'
import {Dialog} from 'primereact/dialog'
import BeatLoader from "react-spinners/BeatLoader";

class Loading extends Dialog {

  constructor(props) {

      super(props);

      this.state = {
      }
  }

  render() {

    if(!this.props.show) {
        return null;
    }

    return (
      <div className="sweet-loading">
          <BeatLoader size={15} color={"#0E8FC4"} loading={true}/>
      </div>
    );
  }
}

export default Loading
