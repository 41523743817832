import React, { lazy, Suspense } from "react";
import { createRoot } from 'react-dom/client';
import Loading from './Components/Loading'

const UserLandingPage = lazy(() => import("./portal/landingPages/UserLandingPage"));
const AdminLandingPage = lazy(() => import("./portal/landingPages/AdminLandingPage"));

const search = window.location.search;
const params = new URLSearchParams(search);
const mode = params.get('Mode');

const container = document.querySelector("#root");
const root = createRoot(container);

if(mode === 'Admin'){

  root.render(<Suspense fallback={<Loading show={true} /> }>
                  <AdminLandingPage />
              </Suspense>);

} else {

  root.render(<Suspense fallback={<Loading show={true} /> }>
                  <UserLandingPage />
              </Suspense>);

}
